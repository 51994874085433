import { sectionStyle } from 'holded/lib/styles';

export const defaultThemeValues = {
  background: '',
  titleColor: '',
  titleSecondaryColor: '',
  descriptionColor: '',
};

export const StatsTheme: StatsThemeType = {
  stats: {
    background: {
      light: sectionStyle.background.light,
      soft: sectionStyle.background.soft,
      gradient: sectionStyle.background.gradient,
      dark: sectionStyle.background.grayDark,
      alternative: sectionStyle.background.secondary,
    },
    titleColor: {
      light: sectionStyle.textColor.default,
      soft: sectionStyle.textColor.default,
      gradient: sectionStyle.textColor.light,
      dark: sectionStyle.textColor.light,
      alternative: sectionStyle.textColor.default,
    },
    titleSecondaryColor: {
      light: sectionStyle.textColor.primary,
      soft: sectionStyle.textColor.primary,
      dark: sectionStyle.textColor.primarySoft,
      gradient: sectionStyle.textColor.light,
      alternative: sectionStyle.textColor.default,
    },
    descriptionColor: {
      light: sectionStyle.textColor.primary,
      soft: sectionStyle.textColor.primary,
      gradient: sectionStyle.textColor.light,
      dark: sectionStyle.textColor.primarySoft,
      alternative: sectionStyle.textColor.default,
    },
  },
};

export type StatsThemeType = {
  stats: {
    background: {
      light: string;
      soft: string;
      gradient: string;
      dark: string;
      alternative: string;
    };
    titleColor: {
      light: string;
      soft: string;
      gradient: string;
      dark: string;
      alternative: string;
    };
    titleSecondaryColor: {
      light: string;
      soft: string;
      dark: string;
      gradient: string;
      alternative: string;
    };
    descriptionColor: {
      light: string;
      soft: string;
      gradient: string;
      dark: string;
      alternative: string;
    };
  };
};
