import { useEffect, useState } from 'react';

import {
  bodyCopySizeTheme as BODY_COPY_SIZE,
  getThemeStyles,
  headingSizeTheme as HEADING_SIZE,
} from 'holded/lib/styles';
import { getCustomTag, isDefault } from 'holded/lib/utils';
import { Stats2Section } from 'holded/modules/cms/domain/components/stats';
import { defaultThemeValues, StatsTheme } from 'holded/modules/cms/ui/sections/stats/StatsTheme';

import styles from './stats.module.scss';

const Stats2 = ({ title, description, stats, background, customTag }: Stats2Section) => {
  const [themeValues, setThemeValues] = useState(defaultThemeValues);

  const CustomTag = getCustomTag('h2', customTag?.headingTag);
  const defaultHeadingSize = isDefault(customTag?.headingSize);
  const defaultBodySize = isDefault(customTag?.bodyCopySize);
  const headingSize = `text-h4 md:${HEADING_SIZE[customTag?.headingSize ?? 'default']}`;

  useEffect(() => {
    const themeStyles = getThemeStyles(StatsTheme.stats, background);
    setThemeValues({ ...themeValues, ...themeStyles });
  }, []);

  return (
    <div className={`${background == 'gradient' ? styles[background] : themeValues.background}`}>
      <div className={`py-12 px-4 max-w-7xl mx-auto sm:py-16 sm:px-6 lg:px-8 lg:py-20`}>
        <div className="max-w-4xl mx-auto text-center">
          <CustomTag
            className={`${themeValues.titleColor} ${
              defaultHeadingSize ? 'md:text-h3 text-h4' : headingSize
            } font-extrabold text-white`}
          >
            {title}
          </CustomTag>
          <p
            className={`${themeValues.descriptionColor} mt-3 ${
              defaultBodySize ? 'text-b1' : `text-b1 md:${BODY_COPY_SIZE[customTag?.bodyCopySize ?? 'default']}`
            } leading-7 sm:mt-4`}
          >
            {description}
          </p>
        </div>
        <dl className="mt-10 text-center sm:max-w-3xl sm:mx-auto sm:grid sm:grid-cols-3 sm:gap-8">
          {stats?.map((stat) => (
            <div key={stat.id} className="flex flex-col lg:pb-0 pb-6 w-4/6 m-auto md:w-full md:m-0">
              <dt className={`${themeValues.titleSecondaryColor} order-2 mt-2 text-lg leading-6 font-medium`}>
                {stat.titleSecondary}
              </dt>
              <dd className={`${themeValues.titleColor} order-1 text-h4 md:text-5xl font-extrabold`}>{stat.title}</dd>
            </div>
          ))}
        </dl>
      </div>
    </div>
  );
};

export default Stats2;
